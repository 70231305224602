import { Button, Form, toast } from '@phoenix/all'
import { SetupLayoutContent, SetupLayoutFooter } from '@setup/components'
import {
  BrowserClient,
  createBoundLocalization,
  Localization,
} from '@workfront/localize-react'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import styled, { css } from 'react-emotion'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom-v5-compat';

import {
  ApplicationService,
  LogoService,
  RedirectUrisService,
  SettingsService,
} from '../../api/api'
import { appTypes, grantTypes } from '../../constants/authMethodsInfo'
import { IApplication, IApplicationResponse } from '../../models/IApplication'
import { Nullable } from '../../models/ILogo'
import { ISettings } from '../../models/ISettings'
import OAuth2PageContainer from '../OAuth2PageContainer'
import { ServiceUserAuthentication } from './ApplicationType/ServiceUserAuthentication'
import { SinglePageWebApplication } from './ApplicationType/SinglePageWebApplication'
import { UserAuthentication } from './ApplicationType/UserAuthentication'
import { BasicInformation } from './BasicInformation/BasicInformation'
import { IUris } from './BasicInformation/RedirectURIs/RedirectURIs'

export const oauth2LocalizationClient = new BrowserClient({
  namespace: 'oauth2',
})
const { useLocalization } = createBoundLocalization(oauth2LocalizationClient)

export const Application: React.FC = () => {
  const params = useParams()
  const applicationId = params.applicationId
    ? params.applicationId
    : params["*"]
      ? params["*"].split('/')[1]
      : "null";
  const [applicationData, setApplicationData] = useState<IApplication>()
  const [redirectUris, setRedirectUris] = useState<IUris[]>([])
  const [settings, setSettings] = useState<ISettings | undefined>()

  const [imgBlob, setImgBlob] = useState<Nullable<Blob[]>>(null)
  const [isLogoRemoved, setIsLogoRemoved] = useState(false)
  const [pageError, setPageError] = useState(-1)
  const [showedError, setShowedError] = useState(false)

  const isJwtFlow = applicationData?.grantType === grantTypes.JWT_FLOW
  const isPkceFlow = applicationData?.type === appTypes.SINGLE_PAGE_WEB

  const [redirectValidationError] = useLocalization(
    'validation.redirect',
    'Redirect URL does not exist or not valid'
  )
  const [successfullySubmitText] = useLocalization(
    'app.successfully.updated',
    'Application updated successfully'
  )
  const [
    applicationNotFoundText,
    applicationNotFoundTextLoaded,
  ] = useLocalization('app.not.found', 'OAuth2 Application is not found')
  const [noAccessText, noAccessTextLoaded] = useLocalization(
    'text.no.access',
    'You do not have sufficient access'
  )
  const [additionalInfo] = useLocalization(
    'additional.info',
    'Additional information'
  )

  let setInitialValues
  if (applicationData) {
    setInitialValues = {
      name: applicationData.name,
      description: applicationData.description ?? '',
      developerName: applicationData.developerName ?? '',
      developerEmailAddress: applicationData.developerEmailAddress ?? '',
      privacyPolicyUrl: applicationData.privacyPolicyUrl ?? '',
    }
  }

  const onLogoUpload = (blob) => setImgBlob(blob)

  const onLogoRemove = () => {
    setImgBlob(null)
    setIsLogoRemoved(true)
  }

  const submitRedirectUris = async (
    applicationId: string,
    redirectUris: IUris[]
  ) => {
    if (redirectUris.length === 0 || redirectUris.find((i) => !i.valid)) {
      toast.error(redirectValidationError)
      return false
    }

    try {
      await RedirectUrisService.post(
        applicationId,
        redirectUris.filter((i) => i.uri).map((i) => i.uri)
      )
      return true
    } catch (error: unknown) {
      toast.error((error as Error).message)
      return false
    }
  }

  const submitLogo = async (applicationId, blob) => {
    if (!blob && applicationData?.logoPath && isLogoRemoved) {
      await LogoService.remove(applicationId)
      return true
    }

    if (!blob) return true

    try {
      const data = new FormData()
      data.append('file', blob[0])
      const { logoPath } = await LogoService.create(applicationId, data)
      if (logoPath && applicationData) {
        setApplicationData({ ...applicationData, logoPath })
        return true
      }
    } catch (error) {
      return false
    }
  }

  const submitRefreshTokenLifetime = async (applicationId: string) => {
    try {
      await SettingsService.update(
        applicationId,
        settings?.refreshTokenAbsoluteLifetime,
        settings?.refreshTokenInactivityLifetime,
        isPkceFlow,
        settings?.shouldRotateRefreshToken
      )
      return true
    } catch (error: unknown) {
      toast.error((error as Error).message)
      return false
    }
  }

  const handleSubmit = async (values) => {
    if (!isJwtFlow) {
      if (!(await submitRedirectUris(applicationId, redirectUris))) return
      if (!(await submitLogo(applicationId, imgBlob))) return
      if (!(await submitRefreshTokenLifetime(applicationId))) return
    }

    try {
      await ApplicationService.update({
        ...values,
        applicationId,
        grantType: applicationData?.grantType,
      })

      setApplicationData((prevState) => ({ ...prevState, ...values }))
      toast.success(successfullySubmitText)
    } catch (error: unknown) {
      toast.error((error as Error).message)
    }
  }

  const getApplicationData = useCallback(async () => {
    try {
      const response: IApplicationResponse = await ApplicationService.get(
        applicationId
      )
      setApplicationData(response.application)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setPageError(e.statusCode)
    }
  }, [applicationId])

  useEffect(() => {
    getApplicationData()
  }, [getApplicationData])

  if (pageError !== -1 && applicationNotFoundTextLoaded && noAccessTextLoaded && !showedError) {
    if (pageError === 403) {
      toast.error(noAccessText)
    } else if (pageError === 404 || pageError === 400) {
      toast.error(applicationNotFoundText)
    }
    setShowedError(true)
  }

  return (
    <>
      {applicationData && (
        <div className={formWrapper}>
          <Form
            initialValues={setInitialValues}
            validateOnBlur
            subscriptions={{ submitting: true, pristine: true }}
            onSubmit={handleSubmit}
          >
            <OAuth2PageContainer pageTitle={<>{applicationData.name}</>}>
              <>
                <SetupLayoutContent>
                  <ContainerStyled>
                    <BasicInformation
                      applicationId={applicationData.applicationId}
                      clientId={applicationData.clientId}
                      setRedirectUris={setRedirectUris}
                      isJwtFlow={isJwtFlow}
                      isPkceFlow={isPkceFlow}
                    />
                    {isJwtFlow ? (
                      <ServiceUserAuthentication
                        applicationData={applicationData}
                        additionalInfo={additionalInfo}
                      />
                    ) : isPkceFlow ? (
                      <SinglePageWebApplication
                        applicationData={applicationData}
                        additionalInfo={additionalInfo}
                        settings={settings}
                        setSettings={setSettings}
                        onLogoUpload={onLogoUpload}
                        onLogoRemove={onLogoRemove}
                        isJwtFlow={isJwtFlow}
                      />
                    ) : (
                      <UserAuthentication
                        applicationData={applicationData}
                        additionalInfo={additionalInfo}
                        settings={settings}
                        setSettings={setSettings}
                        onLogoUpload={onLogoUpload}
                        onLogoRemove={onLogoRemove}
                        isJwtFlow={isJwtFlow}
                      />
                    )}
                  </ContainerStyled>
                </SetupLayoutContent>

                <SetupLayoutFooter sticky>
                  <Footer />
                </SetupLayoutFooter>
              </>
            </OAuth2PageContainer>
          </Form>
        </div>
      )}
    </>
  )
}

export const Footer: React.FC<{
  actionDisabled?: boolean
  cancelDisabled?: boolean
}> = ({ actionDisabled = false, cancelDisabled = false }): ReactElement => {
  return (
    <RootWrapperStyled>
      <div>
        <Button
          primary
          disabled={actionDisabled}
          data-testid="save"
          type="submit"
        >
          <Localization messageKey={'action.save'} fallback={'Save'} />
        </Button>
        <Link to={'/setup/oauth2-apps'}>
          <Button
            text
            disabled={cancelDisabled}
            data-testid="cancel"
            style={{ marginLeft: '8px' }}
          >
            <Localization messageKey={'action.cancel'} fallback={'Cancel'} />
          </Button>
        </Link>
      </div>
    </RootWrapperStyled>
  )
}

const RootWrapperStyled = styled('div')`
  padding: 16px 24px;
  border-top: 1px solid #e0e1e3;
  background: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ContainerStyled = styled('div')`
  padding: 24px;
`

const formWrapper = css`
  height: 100%;
  > form {
    height: 100%;
  }
`
