import { Phoenix } from '@phoenix/all'
import { history } from '@wf-mfe/navigation'
import * as React from 'react'
import { Router } from 'react-router-dom'
import { CompatRoute, CompatRouter, Routes } from 'react-router-dom-v5-compat'

import { Application } from './components/Application/Application'
import { Applications } from './components/Applications/Applications'

export const App: React.FC = () => {
  return (
    <div>
      <Phoenix injectContainers={false}>
        <Router history={history}>
          <CompatRouter>
            <Routes>
              <CompatRoute path="/setup/apps" exact={true}>
                <Applications />
              </CompatRoute>
              <CompatRoute path="/setup/apps/:applicationId">
                <Application />
              </CompatRoute>
            </Routes>
          </CompatRouter>
        </Router>
      </Phoenix>
    </div>
  )
}
